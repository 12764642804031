<template>
  <!-- 头部组件 -->
  <div class="header">
    <div class="HeaderTop">
      <div class="cmain">
        <div class="lf">欢迎光临新橙元！</div>
        <div class="HeaderTel">全国服务热线：<em>400-099-7227</em></div>
      </div>
    </div>
    <div class="HeaderBot">
      <div class="cmain">
        <div class="logo">
          <img src="../assets/images/logo1.jpg" @click="toHome" alt="" />
        </div>
        <div class="HeaderList">
          <ul>
            <li @click="toHome">
              <span class="span1" :class="{ bb: active == 1 }">首页</span>
              <span class="span2" :class="{ bb: active == 1, aa: active == 1 }"
                >HOME</span
              >
              <i class="med"></i>
            </li>
            <li @click="toAbout">
              <span class="span1" :class="{ bb: active == 2 }">关于我们</span>
              <span class="span2" :class="{ bb: active == 2, aa: active == 2 }"
                >ABOUT US</span
              >
              <i class="med"></i>
            </li>
            <li @click="toProdcuts">
              <span class="span1" :class="{ bb: active == 3 }">系列产品</span>
              <span class="span2" :class="{ bb: active == 3, aa: active == 3 }"
                >PRODUCTLINES
              </span>
              <i class="med"></i>
            </li>
            <li @click="toService">
              <span class="span1" :class="{ bb: active == 4 }">业务合作</span>
              <span class="span2" :class="{ bb: active == 4, aa: active == 4 }"
                >COOPERATION</span
              >
              <i class="med"></i>
            </li>
            <li @click="toTome">
              <span class="span1" :class="{ bb: active == 5 }">联系我们</span>
              <span class="span2" :class="{ bb: active == 5, aa: active == 5 }"
                >CONTACT</span
              >
              <i class="med"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active1: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    toHome() {
      this.active = 1;
      this.$router.push("/").catch((err) => {});
    },
    toAbout() {
      this.active = 2;
      this.$router.push("/about").catch((err) => {});
    },
    toProdcuts() {
      this.active = 3;
      this.$router.push("/prodcuts").catch((err) => {});
    },
    toService() {
      this.active = 4;
      this.$router.push("/service").catch((err) => {});
    },
    toTome() {
      this.active = 5;
      this.$router.push("/tome").catch((err) => {});
    },
  },
  created() {
    this.active = this.active1;
  },
};
</script>