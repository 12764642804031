import Axios from 'axios';
import { baseUrl } from './config';
let baseurl = baseUrl;
const axios = Axios.create({
  baseURL: baseurl, // api的base_url
  timeout: 50000,    // 请求超时时间
  headers: { 'content-type': 'application/json' },
});

export const safeFetch = (options) => {
  let data = null;
  let method = options.method ? options.method : 'post';
  if (method == 'post') {
    data = options.params;
    return axios({
      url: options.url,
      method: method.toLocaleLowerCase(),
      data
    });
  }
  if (method == 'get') {
    let query = options.query ? options.query : {};
    return axios({
      url: options.url,
      method: method.toLocaleLowerCase(),
      params: query,
    });
  }
}
