<template>
  <div class="footer">
    <div class="FooterTop">
      <div class="cmain">
        <ul>
          <li class="active" @click="toProdcuts">系列产品</li>
          <li class="active" @click="toService">业务合作</li>
          <li class="active" @click="toAbout">关于我们</li>
          <li class="active" @click="toTome">联系我们</li>

          <!-- <li><a href="##">消费应用产品</a></li>
          <li><a href="##">商业应用产品</a></li>
          <li><a href="##">工业应用产品</a></li> -->
        </ul>
        <ul>
          <!-- <li><a href="##">消费应用服务</a></li>
          <li><a href="##">商业应用服务</a></li>
          <li><a href="##">工业应用服务</a></li> -->
        </ul>
        <ul>
          <!-- <li><a href="##">企业介绍</a></li>
          <li><a href="##">执业资质</a></li>
          <li><a href="##">公司新闻</a></li> -->
        </ul>
        <ul class="ImgList">
          <li class="item">
            <div>
              <img src="../assets/images/ewm1.jpg" alt="" />
              <p>客服微信</p>
            </div>
            <div>
              <img src="../assets/images/ewm2.jpg" alt="" />
              <p>公众号</p>
            </div>
          </li>
        </ul>
        <ul>
          <li class="active">{{ companyDetail.title }}</li>
          <li class="hen"></li>
          <li>地址：重庆市忠县移民生态工业园区</li>
          <li>重庆办事处：{{ companyDetail.address }}</li>
          <li>
            电话：{{ companyDetail.phone }} 邮箱：{{ companyDetail.email }}
          </li>
        </ul>
        <div class="LinkItem">
          <div class="txt1"><em>友情链接 / </em> LINKS</div>
          <div class="txt2"><span class="hen"></span></div>
          <!-- <div class="txt3">
            <div class="LinkMain">点击选择下拉链接</div>
            <div class="LinkMList">
              <ul>
                <li><a href="##">111111</a></li>
                <li><a href="##">222222</a></li>
                <li><a href="##">333333</a></li>
                <li><a href="##">555555</a></li>
                <li><a href="##">666666</a></li>
                <li><a href="##">777777</a></li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="FooterBot">
      <div class="cmain">
        <div>Copyright © 2022 {{ companyDetail.title }} 版权所有</div>
        <div class="ip">
          <img
            src="../assets/images/emblem.png"
            style="color: #fff; margin-right: 10px"
            alt=""
          />
          <a
            href="http://www.beian.gov.cn/portal/index?token=ff4082f8-0fc1-4972-87d2-3ee036cafa9c"
            target="_blank"
            style="color: #fff; margin-right: 10px"
          >
            渝公网安备50010302002480号
          </a>
          <a
            href="http://beian.miit.gov.cn/"
            target="_blank"
            style="color: #fff; margin-right: 10px"
          >
            渝ICP备19009758号-1
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      companyDetail: {},
    };
  },
  methods: {
    toProdcuts() {
      this.$router.push("/prodcuts").catch((err) => {});
    },
    toService() {
      this.$router.push("/service").catch((err) => {});
    },
    toAbout() {
      this.$router.push("/about").catch((err) => {});
    },
    toTome() {
      this.$router.push("/tome").catch((err) => {});
    },
    getCompany() {
      this.$fetch({
        url: "/api/index/company",
      }).then((res) => {
        this.companyDetail = res.data.data;
      });
    },
  },
  created() {
    this.getCompany();
  },
};
</script>
<style lang="less" scoped>
.FooterTop {
  // .cmain {
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  // }
}
.ImgList {
  .item {
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
  }
}
</style>