import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: "新橙元官网"
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: "关于我们"
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '/prodcuts',
    name: 'Prodcuts',
    meta: {
      title: "系列产品"
    },
    component: () => import('../views/Prodcuts.vue')
  },
  {
    path: '/service',
    name: 'Service',
    meta: {
      title: "业务合作"
    },
    component: () => import('../views/Service.vue')
  },
  {
    path: '/tome',
    name: 'Tome',
    meta: {
      title: "联系我们"
    },
    component: () => import('../views/Tome.vue')
  },
  {
    path: '/prodcutsDetail',
    name: 'ProdcutsDetail',
    meta: {
      title: "产品详情"
    },
    component: () => import('../views/ProdcutsDetail.vue')
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 从那个路径跳转而来
  //next() 是一个函数  表示放行
  //next('/login') 强制跳转
  //改变网页标题
  document.title = to.meta.title;

  //当前路径是/login 直接放行
  // if (to.path === "/login") return next()

  //获取sessionStorage中的token值
  // const toKenStr = window.sessionStorage.getItem('token')

  //tokenstr中没有值 则强制跳转到/login
  // if (!toKenStr) return next('/login')

  next();
})
export default router
